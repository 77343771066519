import React, { useCallback, useEffect, useState } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { Currency, currencyEquals, ETHER, TokenAmount, WETH } from '@pancakeswap/sdk'
import { Button, Text, Flex, AddIcon, ArrowDownIcon, CardBody, Message, useModal, LinkExternal } from '@pancakeswap/uikit'

import styled from 'styled-components'


import { RouteComponentProps } from 'react-router-dom'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import { useTranslation } from 'contexts/Localization'
import UnsupportedCurrencyFooter from 'components/UnsupportedCurrencyFooter'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../state'
import { LightCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Layout/Column'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModalZap'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import CurrencySelectPanel from '../../components/CurrencySelectPanel'

import { DoubleCurrencyLogo } from '../../components/Logo'
import { AppHeader, AppBody } from '../../components/App'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { AutoRow, RowBetween } from '../../components/Layout/Row'
import ConnectWalletButton from '../../components/ConnectWalletButton'

import { ROUTER_ADDRESS, ZAP_ADDRESS } from '../../config/constants'
import { PairState } from '../../hooks/usePairs'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { Field, resetMintState } from '../../state/mintcalc/actions'
import { useDerivedMintInfo, useMintActionHandlers, useMintState, useIsTransactionBurnLPs} from '../../state/mintcalc/hooks'

import { useTransactionAdder } from '../../state/transactions/hooks'
import { useGasPrice, useIsExpertMode, useUserSlippageTolerance } from '../../state/user/hooks'
import { calculateGasMargin, calculateSlippageAmount, getRouterContract, getZapContract } from '../../utils'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import Dots from '../../components/Loader/Dots'
// import ConfirmAddModalBottom from './ConfirmAddModalBottom'
import { currencyId } from '../../utils/currencyId'
// import PoolPriceBar from './PoolPriceBar'
import Page from '../Page'
import { Input as NumericalInput } from '../../components/CurrencySelectPanel/NumericalInput'

const StyledLinkExternal = styled(LinkExternal)`
  font-weight: 400;
`


export default function CalcLiquidity({
  match: {
    params: { currencyIdA, currencyIdB },
  },
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const { account, chainId, library } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const gasPrice = useGasPrice()
  const GAS_LIMIT=1000000


  const currencyA = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)

  useEffect(() => {
    if (!currencyIdA && !currencyIdB) {
      dispatch(resetMintState())
    }
  }, [dispatch, currencyIdA, currencyIdB])

  const oneCurrencyIsWETH = Boolean(
    chainId &&
      ((currencyA && currencyEquals(currencyA, WETH[chainId])) ||
        (currencyB && currencyEquals(currencyB, WETH[chainId]))),
  )

  const expertMode = useIsExpertMode()
 
  const tmpFormat = {
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: '',
    fractionGroupSize : 0
  }
  

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
    totalSupply,
    poolProduct,
    LPAAmount,
    LPBAmount,
    shareOfPool,
    poolProductLP,
    LPValue
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

if(liquidityMinted)
{
  // // TP1 // TP2 console.log((`Liquidity Minted : ${liquidityMinted.toFixed(10)}`);
 // // // TP1 // TP2 console.log((`Liquidity Minted : ${liquidityMinted}`);
}



if(pair && totalSupply)
{



    // // TP1 // TP2 console.log((`Pair 1 ${pair.reserve0.raw}`)
    // // TP1 // TP2 console.log((`Pair 2 ${pair.reserve1.raw}`)
    // // TP1 // TP2 console.log((`Total Supply ${totalSupply.raw}`)


    
}
  const isValid = !error

  // // // TP1 // TP2 console.log((`isValid ${isValid}`);
  

  // modal and loading
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  const deadline = useTransactionDeadline() // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance() // custom from users
  const [txHash, setTxHash] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(10) ?? '',
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field]),
      }
    },
    {},
  )

  const atMaxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      }
    },
    {},
  )

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], ZAP_ADDRESS)
  const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], ZAP_ADDRESS)

  const addTransaction = useTransactionAdder()

  async function onAdd() {
    if (!chainId || !library || !account) return
    const router = getRouterContract(chainId, library, account)
    const zap = getZapContract(chainId, library, account)


    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
  //  if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
    if (!parsedAmountA ||  !currencyA ) {
      
  return
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? 0 : allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? 0 : allowedSlippage)[0],
    }

     let estimate
     let method: (...args: any) => Promise<TransactionResponse>
     let args: Array<string | string[] | number>
     let value: BigNumber | null

  
    /*
    if (currencyA === ETHER || currencyB === ETHER) {
      const tokenBIsETH = currencyB === ETHER
      estimate = router.estimateGas.addLiquidityETH
      method = router.addLiquidityETH
      args = [
        wrappedCurrency(tokenBIsETH ? currencyA : currencyB, chainId)?.address ?? '', // token
        (tokenBIsETH ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
        amountsMin[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
        amountsMin[tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
        account,
        deadline.toHexString(),
      ]
      value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString())
    } else {
      estimate = router.estimateGas.addLiquidity
      method = router.addLiquidity
      args = [
        wrappedCurrency(currencyA, chainId)?.address ?? '',
        wrappedCurrency(currencyB, chainId)?.address ?? '',
        parsedAmountA.raw.toString(),
        parsedAmountB.raw.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadline.toHexString(),
      ]
      value = null
    }
    */
   // // // TP1 // TP2 console.log(('Executing Zap');

    if (currencyA === ETHER ) {
      
      // // // TP1 // TP2 console.log((`Zap CurrencyA is Ether method branch`);

      method = zap.zapIn
      args = [
        wrappedCurrency(currencyB, chainId).address,
      ]
      value = BigNumber.from((parsedAmountA).raw.toString())
    
    } 
    else if (currencyB === ETHER || (currencyA.symbol.indexOf("-") > -1))
    {
      
      // // // TP1 // TP2 console.log((`Zap CurrencyB is Ether method branch`);
      
      method = zap.zapOut
      args = [
        wrappedCurrency(currencyA, chainId).address,
        parsedAmountA.raw.toString(),
       
      ]
      value = null


    }
    else
    {
      // // // TP1 // TP2 console.log((`Zap Token To Token method branch`);
      
      method = zap.zapInToken
      args = [
        wrappedCurrency(currencyA, chainId).address,
        parsedAmountA.raw.toString(),
        wrappedCurrency(currencyB, chainId).address,
       
      ]
      value = null


    }

    // const value = BigNumber.from(parsedAmountA.raw.toString())

    // const method=zap.zapIn


    setAttemptingTxn(true);


    await   method(...args, {

          ...(value ? { value } : {}),
          gasLimit: GAS_LIMIT,

          gasPrice,
        }).then((response) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary: `Zapped ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
              currencies[Field.CURRENCY_A]?.symbol
            } To  ${currencies[Field.CURRENCY_B]?.symbol}`,
          })

          setTxHash(response.hash)
        })
      
      
      .catch((err) => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (err?.code !== 4001) {
          console.error(err)
        }
      }).finally(() => {
        setAttemptingTxn(false)
      }
      )
  }


  // // // TP1 // TP2 console.log((`Attempting txn ${attemptingTxn}`);



  
  const modalHeader = () => {
    return noLiquidity ? (
      <Flex alignItems="center">
        <Text fontSize="48px" marginRight="10px">
          {`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol}`}
        </Text>
        <DoubleCurrencyLogo
          currency0={currencies[Field.CURRENCY_A]}
          currency1={currencies[Field.CURRENCY_B]}
          size={30}
        />
      </Flex>
    ) : (
      <AutoColumn>
        <Flex alignItems="center">
          <Text fontSize="48px" marginRight="10px">
            {liquidityMinted?.toSignificant(6)}
          </Text>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={30}
          />
        </Flex>
        <Row>
          <Text fontSize="24px">
            {`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol} Pool Tokens`}
          </Text>
        </Row>
        <Text small textAlign="left" my="24px">
          {t('Output is estimated. If the price changes by more than %slippage%% your transaction will revert.', {
            slippage: allowedSlippage / 100,
          })}
        </Text>
      </AutoColumn>
    )
  }


  const pendingTextBeta = t('Converting %amountA% %symbolA% to %amountB% %symbolB%', {
    amountA: parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    symbolA: currencies[Field.CURRENCY_A]?.symbol ?? '',
    amountB: parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
    symbolB: currencies[Field.CURRENCY_B]?.symbol ?? '',
  })

  const tmpSymbolA= currencies[Field.CURRENCY_A]?.symbol ?? ''
  // If zapping from LP tokens change the message
  const pendingText = ( tmpSymbolA.indexOf("-") > -1) ? t(`Redeeming %amountA% %symbolA% `
  , {
    amountA: parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    symbolA: currencies[Field.CURRENCY_A]?.symbol ?? '',
  }
  
  ) : pendingTextBeta
  // burnLP=false;


  const handleCurrencyASelect = useCallback(
    (currencyA_: Currency) => {

      // // // TP1 // TP2 console.log((`newCurrencyA ${currencyA_.name}`);

      const newCurrencyIdA = currencyId(currencyA_)
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/calc/${currencyIdB}/${currencyIdA}`)
      }
      else if (currencyA_.name.indexOf('-') > -1)
      {
        history.push(`/calc/${newCurrencyIdA}/BNB`)
    

      } 
      else if (currencyIdB) {
        history.push(`/calc/${newCurrencyIdA}/${currencyIdB}`)
      }
     
      else {
        history.push(`/calc/${newCurrencyIdA}`)
      }
    },
    [currencyIdB, history, currencyIdA],
  )
  const handleCurrencyBSelect = useCallback(
    (currencyB_: Currency) => {
      const newCurrencyIdB = currencyId(currencyB_)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/calc/${currencyIdB}/${newCurrencyIdB}`)
        } else {
          history.push(`/calc/${newCurrencyIdB}`)
        }
      } else {
        history.push(`/calc/${currencyIdA || 'BNB'}/${newCurrencyIdB}`)
      }
    },
    [currencyIdA, history, currencyIdB],
  )

  const handleDismissConfirmation = useCallback(() => {
    // if there was a tx hash, we want to clear the input
    // // // TP1 // TP2 console.log((`On dimiss txHash ${txHash}`)
    if (txHash) {
      onFieldAInput('')
    }
    setTxHash('')
  }, [onFieldAInput, txHash])

  const addIsUnsupported = useIsTransactionUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

  // const burnLP = useIsTransactionBurnLPs(currencies?.CURRENCY_A)

  const burnLP = false


 
  return (
    <Page>
      <AppBody>
        <AppHeader
          title={t('Track LP Tokens')}
          subtitle={t('This utility displays the contents of your LP tokens and the Pool Product value. It`s intended use is for monitoring your LP growth from transaction fees')}
          helper={t(
            'Some LP pair like CHR-BUSD, due to CHR token having different decimal counts as compared to other tokens will display as 0.00xx relative to other tokens',
          )}
          backTo="/add/BNB/0x0eD7e52944161450477ee417DE9Cd3a859b14fD0"
        />
        <CardBody>
          <AutoColumn gap="20px">
            {noLiquidity && (
              <ColumnCenter>
                <Message variant="warning">
                  <div>
                    <Text bold mb="8px">
                      {t('No Liquidity')}
                    </Text>
                    <Text mb="8px">{t('Unable to find existing liquidity pool for the paired tokens')}</Text>
                    </div>
                </Message>
              </ColumnCenter>
            )}
         

            <Text>Input LP Token Amount</Text>
               <NumericalInput
                value={formattedAmounts[Field.CURRENCY_A]}
                onUserInput={(val) => {
                  onFieldAInput(val)
                }}
                align="right"
              />
           

            <ColumnCenter>
              <ArrowDownIcon width="16px" />
            </ColumnCenter>

<AutoRow justify="space-around" gap="4px">   { burnLP ?  ( 

              <Text>LPs will be burned and redeemed back to their pairing parts</Text>
            ) : (  


            <CurrencySelectPanel
              value={formattedAmounts[Field.CURRENCY_A]}
              onUserInput={onFieldAInput}
              onCurrencySelect={handleCurrencyASelect}
              onMax={() => {
                onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
              }}
              showMaxButton={false}
              currency={currencies[Field.CURRENCY_A]}
              id="add-liquidity-input-tokenb"
              
              // showCommonBases
              label="Pair A "
              
                    

            />

            
             ) 
             
             
             }

<CurrencySelectPanel
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onFieldBInput}
              onCurrencySelect={handleCurrencyBSelect}
              onMax={() => {
                onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
              }}
              showMaxButton={false}
              currency={currencies[Field.CURRENCY_B]}
              id="add-liquidity-input-tokenb"
              
              // showCommonBases
              label="Pair B "
              
                    

            />
</AutoRow>
         
            {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
              <>
                    <LightCard padding="0px" borderRadius="20px">


                  <RowBetween padding="1rem">
                    <Text fontSize="14px">
                      {noLiquidity ? t('No Pool') : t('LP Composition')}
                    </Text>
                  </RowBetween>{' '}
                  <RowBetween padding="1rem">

                      { pair ? ( 
            
      <AutoRow>

<AutoRow justify="space-around" gap="4px">
              
              <AutoColumn justify="center">
                   <Text fontSize="14px">
                     Approx Value
                   </Text> 
                

                   <Text>
               {LPValue ? LPValue.toSignificant(4, tmpFormat) : "0"} USD
                </Text>
                </AutoColumn>
             
             
                </AutoRow>
      <AutoRow justify="space-around" gap="4px">
              
               <AutoColumn justify="center">
                    <Text fontSize="14px">
                      {pair.token0.symbol} 
                    </Text> 
                 

                    <Text>
                {LPAAmount ? LPAAmount.toSignificant(12, tmpFormat) : "0"}
                 </Text>
                 </AutoColumn>
              
                 <AutoColumn justify="center">
             
                 <Text fontSize="14px">
                      {pair.token1.symbol} 
                    </Text> 
                 

                    <Text>
                {LPBAmount ? LPBAmount.toSignificant(12, tmpFormat) : "0"}
                 </Text>
           </AutoColumn>
                 </AutoRow>
                 <AutoRow justify="space-around" gap="4px">
                 <AutoColumn justify="center">
             
             <Text fontSize="14px">
                  Share of Pool
                </Text> 
             

                <Text>
            {shareOfPool ? shareOfPool.toSignificant(6, tmpFormat) : "0"} %
             </Text>
       </AutoColumn>

       <AutoColumn justify="center">
             
             <Text fontSize="14px">
                  Pool Product LP
                </Text> 
             

                <Text>
            {poolProductLP ? poolProductLP.toSignificant(12, tmpFormat) : "0"}
             </Text>
       </AutoColumn>

                 </AutoRow>

                 </AutoRow>
  ) : (

                        <Text>Loading</Text>

                     ) }
                  </RowBetween>{' '}
                  
                </LightCard>


                <LightCard padding="0px" borderRadius="20px">
                  <RowBetween padding="1rem">
                    <Text fontSize="14px">
                      {noLiquidity ? t('No Pool') : t('Pool Information')}
                    </Text>
                  </RowBetween>{' '}
                  <RowBetween padding="1rem">

                      { pair ? ( 
            <AutoRow >
            
            <AutoRow justify="space-around" gap="4px">
            
           <AutoColumn justify="center">                    
             <Text fontSize="14px">
                      {pair.token0.symbol} 
                    </Text> 
                 

                    <Text>
                {pair.reserve0.toSignificant(12, tmpFormat)}
                 </Text>
                 </AutoColumn>

                 <AutoColumn justify="center">
                    <Text fontSize="14px">
                    {pair.token1.symbol} 
                  </Text> 
            <Text>
                {pair.reserve1.toSignificant(12, tmpFormat)}
                 </Text>
                 </AutoColumn>
            </AutoRow>

            <AutoRow justify="space-around" gap="4px">

            <AutoColumn justify="center">
       
                    <Text fontSize="14px">
                    Total LP Supply 
                     </Text> 
                  <Text fontSize="14px">
                      { totalSupply ? totalSupply.toSignificant(12, tmpFormat) : "0" }
                  </Text> 
                        </AutoColumn>

            <AutoColumn justify="center">
                    
                  <Text fontSize="14px">
                    Pool Product Norm
                     </Text> 
                  <Text fontSize="14px">
                      { poolProduct ? poolProduct.toSignificant(12,  tmpFormat): "0" }

                  </Text> 
                        </AutoColumn>

                        </AutoRow>
                        </AutoRow>
                   ) : (

                        <Text>Loading</Text>

                     ) }
                  </RowBetween>{' '}
                  
                </LightCard>
              </>
            )}

            {pair ?  (
      <StyledLinkExternal color="text" href={`https://pancakeswap.finance/info/pairs/${pair.liquidityToken.address}`}>{t('See Pair Info')}</StyledLinkExternal>

  ) : ""}

          </AutoColumn>
        </CardBody>
      </AppBody>
          <AutoColumn style={{ minWidth: '20rem', width: '100%', maxWidth: '400px', marginTop: '1rem' }}>
            <LightCard>
            <Text fontSize="14px" style={{ textAlign: 'center' }}>
            <span role="img" aria-label="pancake-icon">
              🥞
            </span>{' '}
            {t(
              "The individual token amounts change as the relative price changes however the pool product (TokenA amount x TokenB amount) is always increasing due to transaction fees accumulating in the pool",
            )}
          </Text>
        
          <Text fontSize="14px" style={{ textAlign: 'center' }}>
            <span role="img" aria-label="pancake-icon">
              🥞
            </span>{' '}
            {t(
              "Some tokens such as ALICE and CHR have 6 decimals instead of the standard 18 decimals for ethers and thus their LPs shows much more leading zeroes as compared to other LPs although having equal value",
            )}
          </Text>
        </LightCard>  </AutoColumn>
    </Page>
  )
}

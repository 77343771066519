import { ethers } from 'ethers'
import getRpcUrl from 'utils/getRpcUrl'



//  const RPC_URL = getRpcUrl()

 // const RPC_URL="http://localhost:8545"
// const RPC_URL = "https://bsc-dataseed1.defibit.io"
//  const RPC_URL = "https://bsc.nodereal.io"
//   const RPC_URL = "https://nd-591-318-210.p2pify.com/4eb1e8dd47fff80ac78d4f373e5b0f95"
  
  // const RPC_URL = "http://localhost:8545"
  const RPC_URL = "https://arb1.arbitrum.io/rpc"
//    const RPC_URL ="https://arbitrum-mainnet.infura.io/v3/77795d2fb82d46c7af4e4a4f4b6446a2"

// const RPC_URL = "https://arbitrum-mainnet.infura.io/v3/099fc58e0de9451d80b18d7c74caa7c1"
   // const RPC_URL = "https://rpc.ankr.com/arbitrum"
 //  const RPC_URL ="https://arbitrum-mainnet.infura.io"

// TODOBEAR
// // // TP1 // TP2 console.log((`RPC_URL ${RPC_URL}`)


export const simpleRpcProvider = new ethers.providers.JsonRpcProvider(RPC_URL)

export default null

import BigNumber from 'bignumber.js'
import masterchefABI from 'config/abi/masterchef.json'
import erc20 from 'config/abi/erc20.json'
import pricecalculator from 'config/abi/PriceCalculator.json'
import { BASE_URL, VAULTAPI_URL } from 'config'
import { fetchBuilder, MemoryCache } from 'node-fetch-cache';


// TODOBEAR

import dashboard from 'config/abi/dashboard.json'
import { getAddress, getDashboardAddress, getMasterChefAddress, getPriceCalculatordAddress } from 'utils/addressHelpers'
import { BIG_ONE, BIG_TEN, BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'
import { SerializedFarm, SerializedBigNumber } from '../types'


const options = [
  {
    ttl: 60000
  }];



const vaultAPYURL = `${VAULTAPI_URL}/apy/hippo`
const vaultTVLURL = `${VAULTAPI_URL}/tvl`
const vaultPriceURL = `${VAULTAPI_URL}/lps`


//  const addressDashboard = getDashboardAddress()
//  const addressPriceCalculator = getPriceCalculatordAddress()


// May need to add here directly the LP token price using price calculator
type PublicFarmData = {
  tokenAmountTotal: SerializedBigNumber
  lpTotalInQuoteToken: SerializedBigNumber
  lpTotalSupply: SerializedBigNumber
  tokenPriceVsQuote: SerializedBigNumber
  poolWeight: SerializedBigNumber
  multiplier: string
  poolTVL?: SerializedBigNumber
  newLPTokenPrice?: SerializedBigNumber
  newRewardTokenPrice?: SerializedBigNumber
  newAPR?: string
}


// TODOBEAR on public farms blockchain calls

const fetchFarm = async (farm: SerializedFarm): Promise<PublicFarmData> => {
  const { pid, lpAddresses, token, quoteToken, vaultAddress } = farm
  const lpAddress = getAddress(lpAddresses)

  let tokenBalanceLP
  let quoteTokenBalanceLP
  let lpTokenBalanceMC
  let lpTotalSupply
  let tokenDecimals
  let quoteTokenDecimals


  let tmpPoolTVL = "1";

  try {
    await fetch(vaultTVLURL, { mode: 'cors' })
      .then((response) => { return response.json() })
      .then((data) => {
        //  // // TP1 // TP2 console.log(("Remote url APYs")
        //  // // TP1 // TP2 console.log((data);
        const tmpChainTVL = data["42161"] ?? "";


        tmpPoolTVL = tmpChainTVL[farm.pid] ?? "10";

        // // TP1 // TP2 console.log((`tmpPoolTVL ${farm.pid} ${tmpPoolTVL}`);

        //  // // TP1 // TP2 console.log((`mytmpRewardAPR : ${mytmpRewardAPR}`)
      })
  }
  catch (err) {
    // // TP1 // TP2 console.log(('there was an error fetching TVL', err);
  }




  let tmp2LPTokenPrice
  let tmp2RewardTokenPrice

  if (farm.pid > 0) {

    try {
      await fetch(vaultPriceURL, { mode: 'cors' })
        .then((response) => { return response.json() })
        .then((data) => {
          //  // // TP1 // TP2 console.log((data);
          tmp2LPTokenPrice = new BigNumber(data[farm.lpAddresses[42161]]).multipliedBy(BIG_TEN.pow(18).toString());
          tmp2RewardTokenPrice = new BigNumber(data[farm.vaultRewardTokenAddress]).multipliedBy(BIG_TEN.pow(farm.vaultRewardTokenDecimal));


          // // TP1 // TP2 console.log((`prices retrieve : ${farm.pid} ${farm.lpAddresses[42161]} ${data[farm.lpAddresses[42161]]} ${tmp2LPTokenPrice} rewardTokenPrice: ${tmp2RewardTokenPrice}`);

        })
    }
    catch (err) {
      // // TP1 // TP2 console.log(('there was an error fetching TVL', err);
    }



  }
  else {
    tmp2LPTokenPrice = new BigNumber(0)
    tmp2RewardTokenPrice = new BigNumber(0)



  }


  // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
  const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

  // Raw amount of token in the LP, including those not staked
  const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))
  const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals))

  // Amount of quoteToken in the LP that are staked in the MC
  const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)

  // Total staked in LP, in quote token value
  const lpTotalInQuoteToken = quoteTokenAmountMc.times(new BigNumber(2))

  // TODO Change in MasterchefV2
  // const allocPoint = info ? new BigNumber(info.allocPoint?._hex) : BIG_ONE
  const allocPoint = new BigNumber("1");

  // const poolWeight = totalAllocPoint ? allocPoint.div(new BigNumber(totalAllocPoint)) : BIG_ONE
  const poolWeight = new BigNumber("1");



  // // // TP1 // TP2 console.log((`============== TOJSON tmp2LPTokenPrice ${tmp2LPTokenPrice.toJSON()}`)
  const shdTmpPoolTVL = new BigNumber(tmpPoolTVL).multipliedBy(new BigNumber(1000000000000000000));

  // let shdTmpPoolTVL2;
  let shdTmpPoolTVL02;

  const wei1 = new BigNumber("1");


  const shdTmpPoolTVL3 = shdTmpPoolTVL;

  let mytmpRewardAPR;


  try {
    await fetch(vaultAPYURL, { mode: 'cors' })
      .then((response) => { return response.json() })
      .then((data) => {

        //  // // TP1 // TP2 console.log(("Remote url APYs")
        //  // // TP1 // TP2 console.log((data);

        mytmpRewardAPR = data[pid?.toString()] ?? 0

        // // TP1 // TP2 console.log((`mytmpRewardAPR ${pid} : ${mytmpRewardAPR}`)
      })
  }
  catch (err) {
    //  // // TP1 // TP2 console.log(('there was an error', err);
  }

  // // // TP1 // TP2 console.log((`shdTmpPoolTVL2 : ${shdTmpPoolTVL3}`);
  // // // TP1 // TP2 console.log((`mytmpRewardAPR 2 : ${mytmpRewardAPR}`)

  // // TP1 // TP2 console.log((`before return fetchpublicFarmDat ${pid} ${tmp2LPTokenPrice} ${mytmpRewardAPR} ${shdTmpPoolTVL3}`);

  return {
    tokenAmountTotal: new BigNumber(1).toJSON(),

    lpTotalSupply: new BigNumber(1).toJSON(),

    lpTotalInQuoteToken: new BigNumber(1).toJSON(),

    tokenPriceVsQuote: new BigNumber(1).toJSON(),

    poolWeight: new BigNumber(1).toJSON(),

    multiplier: `${farm.multiplier}`,

    poolTVL: shdTmpPoolTVL3.toJSON(),
    newLPTokenPrice: tmp2LPTokenPrice.toJSON(),

    newRewardTokenPrice: tmp2RewardTokenPrice.toJSON(),

    newAPR: mytmpRewardAPR.toString()

  }
}

export default fetchFarm

import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'CAKE',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      42161: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    },
    token: serializedTokens.wbnb,
    quoteToken: serializedTokens.wbnb,
    vaultAddress: '0x7D67e067b5ba8C9f64487d1a87267755c3937166',
    vaultName: 'DEF',
    vaultType: 'CAKE',
    vaultRewardToken: 'CAKE',
    vaultRewardTokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    vaultRewardTokenDecimal: 18,
    vaultBearExempt: true





  },
  {
    pid: 251,
    lpSymbol: 'BADBUNNY-USDT',
    lpAddresses: {
      97: '0x3ed8936cAFDF85cfDBa29Fbe5940A5b0524824F4',
      42161: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
    vaultAddress: '0x7D67e067b5ba8C9f64487d1a87267755c3937166',
    vaultName: 'DEF',
    vaultType: 'CAKE',
    vaultRewardToken: 'CAKE',
    vaultRewardTokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    vaultRewardTokenDecimal: 18,
    vaultBearExempt: true

    

  },
  {
    pid: 252,
    lpSymbol: 'BUSD-BNB',
    lpAddresses: {
      97: '',
      42161: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
    vaultAddress: '0x7D67e067b5ba8C9f64487d1a87267755c3937166',
    vaultName: 'BUSD-BNB-Flip Maximizer',
    vaultType: 'FLIP',
    vaultRewardToken: 'BUSD-BNB',
    vaultRewardTokenAddress: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    vaultRewardTokenDecimal: 18,
    vaultBearExempt: true
    

  },
  /**
   * V3 by order of release (some may be out of PID order due to multiplier boost)
   */
   
/*
   {
    pid: 399,
    lpSymbol: 'CAKE-BNB LP',
    lpAddresses: {
      97: '',
      42161: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.bnb,
    multiplier: '0x',
    vaultAddress: '0x8d7f50F076D1acE3E313b0b0B9c06916eAf41Bc4',
    vaultName: 'CAKE-BNB Cake Maximizer',
    vaultType: 'CAKE',
    vaultRewardToken: 'CAKE',
    vaultRewardTokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    vaultRewardTokenDecimal: 18,
    vaultBearExempt: true
  
   
   
  },
*/

{
    pid: 9002,
    lpSymbol: 'USDT-USDC.e', 
    lpAddresses: {
      97: '',
      42161: '0x7f90122BF0700F9E7e1F688fe926940E8839F353',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.usdc,
    multiplier: '77x',
    // isStable: true,
    // stablePool: "0xc2F5B9a3d9138ab2B74d581fC11346219eBf43Fe",

    vaultAddress: '0x416D1614AfeB69FF8eD16AD2E4B39E98d8BBDF8B',
    vaultName: 'USDT-USDC.e',
    vaultType: 'Flip',
    vaultRewardToken: 'USDT-USDC.e',
    vaultRewardTokenAddress: '0x7f90122BF0700F9E7e1F688fe926940E8839F353',
    vaultRewardTokenDecimal: 18,
    vaultBearExempt: true,
    farmType: 1,
    farmInfo: 'https://curve.fi/#/arbitrum/pools/2pool/swap'
   
   
  },

  {
    pid: 9005,
    lpSymbol: 'crvUSD-BTC-ETH', 
    lpAddresses: {
      97: '',
      42161: '0x82670f35306253222F8a165869B28c64739ac62e',
    },
    token: serializedTokens.crvusdtricrypto,
    quoteToken: serializedTokens.crv,
    multiplier: '77x',
    // isStable: true,
    // stablePool: "0xc2F5B9a3d9138ab2B74d581fC11346219eBf43Fe",

    vaultAddress: '0xf765B99A8E0464887395669F4B4076290797E6D4',
    vaultName: 'crvUSD TriCrypto',
    vaultType: 'Flip',
    vaultRewardToken: 'crvUSD-BTC-ETH',
    vaultRewardTokenAddress: '0x82670f35306253222F8a165869B28c64739ac62e',
    vaultRewardTokenDecimal: 18,
    vaultBearExempt: true,
    farmType: 1,
    farmInfo: 'https://curve.fi/#/arbitrum/pools/factory-tricrypto-9/swap'
   
   
  },
  {
    pid: 9006,
    lpSymbol: 'crvUSD-USDT', 
    lpAddresses: {
      97: '',
      42161: '0x73aF1150F265419Ef8a5DB41908B700C32D49135',
    },
    token: serializedTokens.crvusd,
    quoteToken: serializedTokens.usdt,
    multiplier: '77x',
    // isStable: true,
    // stablePool: "0xc2F5B9a3d9138ab2B74d581fC11346219eBf43Fe",

    vaultAddress: '0x2E2BF2cf6dF2CFB5ceF234B75128d5fe9FD55468',
    vaultName: 'crvUSD-USDT',
    vaultType: 'Flip',
    vaultRewardToken: 'crvUSD-USDT',
    vaultRewardTokenAddress: '0x73aF1150F265419Ef8a5DB41908B700C32D49135',
    vaultRewardTokenDecimal: 18,
    vaultBearExempt: true,
    farmType: 1,
    farmInfo: 'https://curve.fi/#/arbitrum/pools/factory-stable-ng-0/swap'
   
   
  },
{
  pid: 9102,
  lpSymbol: 'ARB-ETH', 
  lpAddresses: {
    97: '',
    42161: '0xBF6CBb1F40a542aF50839CaD01b0dc1747F11e18',
  },
  token: serializedTokens.arb,
  quoteToken: serializedTokens.weth,
  multiplier: '0x',
  // isStable: true,
  // stablePool: "0xc2F5B9a3d9138ab2B74d581fC11346219eBf43Fe",

  vaultAddress: '0xEF5d3FA346510ed5Ccbc81183F1AF90D1aB06720',
  vaultName: 'ARB-ETH Maximizer',
  vaultType: 'Flip',
  vaultRewardToken: 'ARB-ETH',
  vaultRewardTokenAddress: '0xBF6CBb1F40a542aF50839CaD01b0dc1747F11e18',
  vaultRewardTokenDecimal: 18,
  vaultBearExempt: true,
  farmType: 2,
  farmInfo: 'https://www.sushi.com/earn/arb1:0xBF6CBb1F40a542aF50839CaD01b0dc1747F11e18'
 
 
}
,
  {
    pid: 9001,
    lpSymbol: 'wstETH-ETH', 
    lpAddresses: {
      97: '',
      42161: '0xDbcD16e622c95AcB2650b38eC799f76BFC557a0b',
    },
    token: serializedTokens.wsteth,
    quoteToken: serializedTokens.weth,
    multiplier: '0x',
    vaultAddress: '0x7D67e067b5ba8C9f64487d1a87267755c3937166',
    vaultName: 'wstETH-ETH',
    vaultType: 'Flip',
    vaultRewardToken: 'wstETH-ETH',
    vaultRewardTokenAddress: '0xDbcD16e622c95AcB2650b38eC799f76BFC557a0b',
    vaultRewardTokenDecimal: 18,
    vaultBearExempt: true,
    farmType: 1,
    farmInfo: 'https://curve.fi/#/arbitrum/pools/wsteth/swap'

   
   
  },

  {
    pid: 9003,
    lpSymbol: 'USDT-BTC-ETH', 
    lpAddresses: {
      97: '',
      42161: '0x8e0B8c8BB9db49a46697F3a5Bb8A308e744821D2',
    },
    token: serializedTokens.tricrypto,
    quoteToken: serializedTokens.crv,
    multiplier: '0x',
    // isStable: true,
    // stablePool: "0xc2F5B9a3d9138ab2B74d581fC11346219eBf43Fe",

    vaultAddress: '0x4a019C34fF6929c211E5a42dd0850Df8f407b81D',
    vaultName: 'TriCrypto Curve',
    vaultType: 'Flip',
    vaultRewardToken: 'USDT-BTC-ETH',
    vaultRewardTokenAddress: '0x8e0B8c8BB9db49a46697F3a5Bb8A308e744821D2',
    vaultRewardTokenDecimal: 18,
    vaultBearExempt: true,
    farmType: 1,
    farmInfo: 'https://curve.fi/#/arbitrum/pools/tricrypto/swap'
   
   
  },

  
  
  {
    pid: 9101,
    lpSymbol: 'MAGIC-ETH', 
    lpAddresses: {
      97: '',
      42161: '0xB7E50106A5bd3Cf21AF210A755F9C8740890A8c9',
    },
    token: serializedTokens.magic,
    quoteToken: serializedTokens.weth,
    multiplier: '77x',
    // isStable: true,
    // stablePool: "0xc2F5B9a3d9138ab2B74d581fC11346219eBf43Fe",

    vaultAddress: '0x92F3e95dd88015E6c8dE59207bb8b05FA18A2D11',
    vaultName: 'MAGIC-ETH',
    vaultType: 'Flip',
    vaultRewardToken: 'MAGIC-ETH',
    vaultRewardTokenAddress: '0xB7E50106A5bd3Cf21AF210A755F9C8740890A8c9',
    vaultRewardTokenDecimal: 18,
    vaultBearExempt: true,
    farmType: 2,
    farmInfo: 'https://www.sushi.com/earn/arb1:0xb7e50106a5bd3cf21af210a755f9c8740890a8c9'
   
   
  }


]

export default farms

import { ethers } from 'ethers'
import { getMulticallContract } from 'utils/contractHelpers'
import { MultiCallResponse } from './types'

export interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (example: balanceOf)
  params?: any[] // Function params
}

interface MulticallOptions {
  requireSuccess?: boolean
}

const multicall = async <T = any>(abi: any[], calls: Call[]): Promise<T> => {

    // TP1 // TP2 console.log((`=================== multicall() X02 ============================`); 
     // TP1 // TP2 console.log((`calls ${calls[0].address} ${calls[0].name}` );

  try {
    const multi = getMulticallContract()
    const itf = new ethers.utils.Interface(abi)

     // TP1 // TP2 console.log((`callsdata : ${JSON.stringify(calls)}`);

      
    const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
   

    // TP1 // TP2 console.log((`callsdata : ${JSON.stringify(calldata)}`);

    const { returnData } = await multi.aggregate(calldata)

   // TP1 // TP2 console.log((`returnData :  ${returnData}`);

    const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call))

  //   // // TP1 // TP2 console.log((`Res :  ${res}`);

    return res as any
  } catch (error) {
    
    // // TP1 // TP2 console.log((`Exception occured ${error}`)
     throw new Error("error");


 

  }
  return null as any
}

/**
 * Multicall V2 uses the new "tryAggregate" function. It is different in 2 ways
 *
 * 1. If "requireSuccess" is false multicall will not bail out if one of the calls fails
 * 2. The return includes a boolean whether the call was successful e.g. [wasSuccessful, callResult]
 */
export const multicallv2 = async <T = any>(
  abi: any[],
  calls: Call[],
  options: MulticallOptions = { requireSuccess: true },
): Promise<MultiCallResponse<T>> => {
  
   // let returnData;
  
    try {
  const { requireSuccess } = options
  const multi = getMulticallContract()
  const itf = new ethers.utils.Interface(abi)

  
  const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
  
  // // TP1 // TP2 console.log((`multicallv2 requireSucess ${requireSuccess}`);
  
//  // // TP1 // TP2 console.log((`multicallv2 calldata ${calldata}`);

  const callSize = calldata.length;

  const chunkSize = 20;

  let returnData= [];
 // var tmpChunkSize;
 // var chunk;
//  var returnDataChunk;

/* eslint-disable */
  for (let i = 0; i < calldata.length; i += chunkSize) {


  //  // // TP1 // TP2 console.log((`i  : ${i} calldata.length ${calldata.length} length-i : ${calldata.length-i}  ` );
   const tmpChunkSize = Math.min(chunkSize, calldata.length-i);
    const    chunk = calldata.slice(i, i + tmpChunkSize);

     //   // // TP1 // TP2 console.log((`multicallv2 calldata chunk ${i} : ${tmpChunkSize} ; ${chunk}`);
      const returnDataChunk = await multi.tryAggregate(requireSuccess, chunk);

   // // // TP1 // TP2 console.log((`multicallv2 return chunk ${i} ${returnDataChunk}`);
    returnData = returnData.concat(returnDataChunk);

}
/* eslint-enable */
  
 // const returnData = await multi.tryAggregate(requireSuccess, calldata)
  
// // // TP1 // TP2 console.log(("some here");

// // // TP1 // TP2 console.log((`mapping return data ${returnData}`);

// // // TP1 // TP2 console.log(("just before");


  const res = returnData.map((call, i2) => {
    const [result, data] = call
    return result ? itf.decodeFunctionResult(calls[i2].name, data) : null
  })

//  // // TP1 // TP2 console.log((`multicallv2 res ${res}`);
  

  return res as any;
}
  catch (error) {
    // // TP1 // TP2 console.log((`Exception occured ${error}`)
    
    throw new Error("error");


 

 }
  


}

function spliceIntoChunks(arr, chunkSize) {
  const res = [];
  while (arr.length > 0) {
      const chunk = arr.splice(0, chunkSize);
      res.push(chunk);
  }
  return res;
}

export default multicall

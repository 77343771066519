import { SerializedFarm } from 'state/types'
import fetchPublicFarmData from './fetchPublicFarmData'

const fetchFarm = async (farm: SerializedFarm): Promise<SerializedFarm> => {
  const farmPublicData = await fetchPublicFarmData(farm)

//  // // TP1 // TP2 console.log((`New APR in fetchFarm1 ${farmPublicData.newAPR}`);

  return { ...farm, ...farmPublicData }
}

export default fetchFarm

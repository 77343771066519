import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
    
      {
        label: t('Blog'),
        href: 'https://medium.com/@happyhippodefi',
      },

      {
        label: t('Wiki/Manual'),
        href: 'https://happy-hippo.gitbook.io',
      },
      
  
    ],
    isHighlighted: false,
  },
 
 
]

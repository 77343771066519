import { ChainId, Token } from '@pancakeswap/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  wbnb: new Token(
    MAINNET,
    '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: new Token(MAINNET, '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18, 'BNB', 'BNB', 'https://www.binance.com/'),
  cake: new Token(
    MAINNET,
    '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    18,
    'CAKE',
    'PancakeSwap Token',
    'https://pancakeswap.finance/',
  ),
  eth: new Token(
    MAINNET,
    '0x0000000000000000000000000000000000000000',
    18,
    'ETH',
    'Ethereum',
    'https://www.ethereum.org/',
  ),
  
  weth: new Token(
    MAINNET,
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    18,
    'WETH',
    'Ethereum',
    'https://www.ethereum.org/',
  ),
  wsteth: new Token(
    MAINNET,
    '0x5979D7b546E38E414F7E9822514be443A4800529',
    18,
    'wstETH',
    'Lido staked ETH',
    'https://www.ethereum.org/',
  ),
  
  magic: new Token(
    MAINNET,
    '0x539bdE0d7Dbd336b79148AA742883198BBF60342',
    18,
    'MAGIC',
    'TreasureDAO',
    'https://www.treasure.dao',
  ),
 
  busd: new Token(
    MAINNET,
    '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    18,
    'BUSD',
    'BUSD Stablecoin',
    'https://www.makerdao.com/',
  ),
 
  dai: new Token(
    MAINNET,
    '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    18,
    'DAI',
    'Dai Stablecoin',
    'https://www.makerdao.com/',
  ),
  usdt: new Token(
    MAINNET,
    '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    6,
    'USDT',
    'Tether USD',
    'https://tether.to/',
  ),
  usdc: new Token(
    MAINNET,
    '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    6,
    'USDC.e',
    'Bridged USDC Coin',
    'https://www.centre.io/usdc',
  )
 ,
  tricrypto: new Token(
    MAINNET,
    '0x8e0B8c8BB9db49a46697F3a5Bb8A308e744821D2',
    18,
    'CRV3Crypto',
    'USDT-WBTC-ETH Curve LP',
    'https://www.curve.fi/',
  ),

  crvusdtricrypto: new Token(
    MAINNET,
    '0x82670f35306253222F8a165869B28c64739ac62e',
    18,
    'CRVUSD3Crypto',
    'crvUSD-WBTC-ETH Curve LP',
    'https://www.curve.fi/',

  )
 ,

 crvusd: new Token(
  MAINNET,
  '0x498Bf2B1e120FeD3ad3D42EA2165E9b73f99C1e5',
  18,
  'crvUSD',
  'crvUSD',
  'https://www.curve.fi/',

)
,

 crv: new Token(
    MAINNET,
    '0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978',
    18,
    'CRV',
    'Curve Token',
    'https://www.curve.fi/',
  ),

 arb: new Token(
    MAINNET,
    '0x912CE59144191C1204E64559FE8253a0e49E6548',
    18,
    'ARB',
    'Arbitrum Token',
    'https://www.arbitrum.foundation',
  ),
  

}

export const testnetTokens = {
  wbnb: new Token(
    TESTNET,
    '0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  cake: new Token(
    TESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'CAKE',
    'PancakeSwap Token',
    'https://pancakeswap.finance/',
  ),
  busd: new Token(
    TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  syrup: new Token(
    TESTNET,
    '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    18,
    'SYRUP',
    'SyrupBar Token',
    'https://pancakeswap.finance/',
  ),
  bake: new Token(
    TESTNET,
    '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    18,
    'BAKE',
    'Bakeryswap Token',
    'https://www.bakeryswap.org/',
  ),



}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()

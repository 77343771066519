import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import { ConnectorNames } from '@pancakeswap/uikit'
import { ethers } from 'ethers'
import getNodeUrl from './getRpcUrl'

const POLLING_INTERVAL = 12000
// const rpcUrl = getNodeUrl()
// const rpcUrl = "https://bsc.nodereal.io"
 // const rpcUrl = "https://nd-591-318-210.p2pify.com/4eb1e8dd47fff80ac78d4f373e5b0f95"
  
   const rpcUrl = "https://arb1.arbitrum.io/rpc"
 // const rpcUrl = "https://arbitrum-mainnet.infura.io/v3/77795d2fb82d46c7af4e4a4f4b6446a2"
 //  const rpcUrl = "https://arbitrum-mainnet.infura.io/v3/099fc58e0de9451d80b18d7c74caa7c1"
 // const rpcUrl = "https://rpc.ankr.com/arbitrum"
  // const rpcUrl = "https://arbitrum-mainnet.infura.io"

const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)

// // TP1 // TP2 console.log((`rpcUrl ${rpcUrl} chainID ${chainId}`);

const injected = new InjectedConnector({ supportedChainIds: [chainId] })

const walletconnect = new WalletConnectConnector({
  rpc: { [chainId]: rpcUrl },
  qrcode: true,
  pollingInterval: POLLING_INTERVAL, 
})

const bscConnector = new BscConnector({ supportedChainIds: [chainId] })

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.BSC]: bscConnector,
  [ConnectorNames.Blocto]: async () => {
    const { BloctoConnector } = await import('@blocto/blocto-connector')
    return new BloctoConnector({ chainId, rpc: rpcUrl })
  },
  [ConnectorNames.WalletLink]: async () => {
    const { WalletLinkConnector } = await import('@web3-react/walletlink-connector')
    return new WalletLinkConnector({
      url: rpcUrl,
      appName: 'PancakeSwap',
      appLogoUrl: 'https://pancakeswap.com/logo.png',
      supportedChainIds: [chainId],
    })
  },
} as const


export const getLibrary = (provider): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = POLLING_INTERVAL
  return library
}

/**
 * BSC Wallet requires a different sign method
 * @see https://docs.binance.org/smart-chain/wallet/wallet_api.html#binancechainbnbsignaddress-string-message-string-promisepublickey-string-signature-string
 */
export const signMessage = async (provider: any, account: string, message: string): Promise<string> => {
  if (window.BinanceChain) {
    const { signature } = await window.BinanceChain.bnbSign(account, message)
    return signature
  }

  /**
   * Wallet Connect does not sign the message correctly unless you use their method
   * @see https://github.com/WalletConnect/walletconnect-monorepo/issues/462
   */
  if (provider.provider?.wc) {
    const wcMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message))
    const signature = await provider.provider?.wc.signPersonalMessage([wcMessage, account])
    return signature
  }

  return provider.getSigner(account).signMessage(message)
}

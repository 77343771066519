import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
// TODOBEAR
import dashboardABI from 'config/abi/dashboard.json'
import istrategyABI from 'config/abi/istrategy.json'

import  multicall, {multicallv2} from 'utils/multicall'
// import  multicall  from 'utils/multicall'

import { getAddress, getMasterChefAddress, getDashboardAddress, getFeeManagerAddress } from 'utils/addressHelpers'
import { SerializedFarmConfig } from 'config/constants/types'
import { getFeeManagerContract } from 'utils/contractHelpers'
import { stringify } from 'querystring'
import { parse } from 'path'

export const fetchFarmUserAllowances = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  
  const masterChefAddress = getMasterChefAddress()
  // TODOBEAR
  // const vaultAddress = farmsToFetch.vaultAddress;


  const calls = farmsToFetch.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    const vaultAddress = farm.vaultAddress;
    
    return { address: lpContractAddress, name: 'allowance', params: [account, vaultAddress] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const calls = farmsToFetch.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmUserStakedBalances = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress()
  const dashboardAddress = getDashboardAddress()

  // // TP1 // TP2 console.log(("fetchFarmUserStakedBalance");
  
  const calls = farmsToFetch.map((farm) => {
    return {
  //    address: masterChefAddress,
  address: farm.vaultAddress,
      
  name: 'principalOf',
      params: [account],
    }
  })

  const callsDepositedAt = farmsToFetch.map((farm) => {
    return {
  //    address: masterChefAddress,
  address: farm.vaultAddress,
      
  name: 'depositedAt',
      params: [account],
    }
  })
    
  const feeManagerContract = getFeeManagerContract(getFeeManagerAddress());
  const _feeDuration = await feeManagerContract.WITHDRAWAL_FEE_FREE_PERIOD();
  const _feePercentage = await feeManagerContract.WITHDRAWAL_FEE();

  
  
//   const rawStakedBalances = await multicall(dashboardABI, calls)
  const rawStakedBalances = await multicallv2(istrategyABI, calls, { requireSuccess: true })
  const rawDepositedAt = await multicallv2(istrategyABI, callsDepositedAt, { requireSuccess: true })
  
  // // // TP1 // TP2 console.log((`rawStakedBalance : ${rawStakedBalances}`);

  /*
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance1) => {
    // // // TP1 // TP2 console.log((`stakedbalance ${(stakedBalance1[0])[9]}`)
    
    // // // TP1 // TP2 console.log((`stakedbalance.depositedAt ${stakedBalance1[9]}`)
    // // TP1 // TP2 console.log((`stakedBlance : ${JSON.stringify(stakedBalance1)}`);
    return { stakedBalance: new BigNumber((stakedBalance1)[2]._hex).toJSON(), depositedAt: (stakedBalance1[0])[9]._hex, feeDuration: (stakedBalance1[0])[10]._hex, feePercentage: (stakedBalance1[0])[11]._hex}
  })
*/

  const parsedStakedBalances2: { stakedBalance: any, depositedAt: any, feeDuration: any, feePercentage: any }[] = new Array(0);


  for (let i = 0; i < rawStakedBalances.length ; i++ )
  {
    // TP1 // TP2 console.log((`feePercentage ${JSON.stringify(_feePercentage)}`);
    const tmpVar = {stakedBalance: rawStakedBalances[i][0]._hex, depositedAt: (rawDepositedAt[i])[0]._hex, feeDuration: _feeDuration._hex,  feePercentage: _feePercentage._hex}    

   parsedStakedBalances2.push(tmpVar);

    
  }

//  // // TP1 // TP2 console.log((`parsedStakedBalances2 ${JSON.stringify(parsedStakedBalances2)}`);


  return parsedStakedBalances2
}

export const fetchFarmUserEarnings = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress()

  const dashboardAddress = getDashboardAddress()

  // // TP1 // TP2 console.log(("fetchFarmUserEarnings");
  
  const calls = farmsToFetch.map((farm) => {
    return {
      // address: masterChefAddress,
      address: farm.vaultAddress,
     
      name: 'earned',
      params: [account],
    }
  })

 // const rawEarnings = await multicall(dashboardABI, calls)
 const rawEarnings = await multicallv2(istrategyABI, calls, { requireSuccess: false })
  
  // May need to copy this
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings[0]._hex).toJSON()

  })
  return parsedEarnings
}

import { SerializedFarmConfig } from 'config/constants/types'
import fetchFarm from './fetchFarm'

const fetchFarms = async (farmsToFetch: SerializedFarmConfig[]) => {
  const data = await Promise.all(
    farmsToFetch.map(async (farmConfig) => {
      const farm = await fetchFarm(farmConfig)

      // // TP1 // TP2 console.log((`New APR in fetchFarmS ${farm.pid } ${farm.newAPR}`);
  
      const serializedFarm = { ...farm, token: farm.token, quoteToken: farm.quoteToken, poolTVL : farm.poolTVL,  newLPTokenPrice: farm.newLPTokenPrice, newRewardTokenPrice: farm.newRewardTokenPrice, newAPR: farm.newAPR }
      return serializedFarm
    }),
  )
  return data
}

export default fetchFarms

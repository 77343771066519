import { BigNumber } from '@ethersproject/bignumber'
import {ethers }from 'ethers';


import { Currency, Percent, Price } from '@pancakeswap/sdk'
import React from 'react'
import { Text } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { AutoColumn } from '../../components/Layout/Column'
import { AutoRow } from '../../components/Layout/Row'
import { ONE_BIPS } from '../../config/constants'
import { Field } from '../../state/mint/actions'


function PoolPriceBar({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price,
  comp1,
  comp2
}: {
  currencies: { [field in Field]?: Currency }
  noLiquidity?: boolean
  poolTokenPercentage?: Percent
  price?: Price
  comp1?: string
  comp2?: string 
}) {
  const { t } = useTranslation()
  return (
    <AutoColumn gap="md">
      <AutoRow justify="space-around" gap="4px">
     <AutoColumn>  <Text>{currencies[Field.CURRENCY_A]?.symbol} ${parseFloat(comp1).toFixed(4)} USD</Text>
     </AutoColumn>       
     <AutoColumn>  <Text>{currencies[Field.CURRENCY_B]?.symbol} ${parseFloat(comp2).toFixed(4)} USD</Text>
     </AutoColumn>       
   
      </AutoRow>
      

      <AutoRow justify="space-around" gap="4px">
        <AutoColumn justify="center">
          
          
          <Text>{price?.toSignificant(6) ?? '-'}</Text>

          <Text fontSize="14px" pt={1}>
            {t('%assetA% per %assetB%', {
              assetA: currencies[Field.CURRENCY_B]?.symbol ?? '',
              assetB: currencies[Field.CURRENCY_A]?.symbol ?? '',
            })}
          </Text>
        </AutoColumn>
        <AutoColumn justify="center">
          <Text>{price?.invert()?.toSignificant(6) ?? '-'}</Text>
          <Text fontSize="14px" pt={1}>
            {t('%assetA% per %assetB%', {
              assetA: currencies[Field.CURRENCY_A]?.symbol ?? '',
              assetB: currencies[Field.CURRENCY_B]?.symbol ?? '',
            })}
          </Text>
        </AutoColumn>
       
      </AutoRow>
    </AutoColumn>
  )
}

export default PoolPriceBar

import { Currency, CurrencyAmount, ETHER, JSBI, Pair, Percent, Price, Token, TokenAmount } from '@pancakeswap/sdk'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { PairState, usePair } from 'hooks/usePairs'
import useTotalSupply from 'hooks/useTotalSupply'
import { getPriceCalculatordAddress } from 'utils/addressHelpers'
import { Contract } from '@ethersproject/contracts'
import priceCalculatorABI from 'config/abi/PriceCalculator.json'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { useTokenPrice } from 'hooks/useTokenPrice'
import { useTokenPriceDecimals } from 'hooks/useTokenPriceDecimals'

import { BigNumber } from '@ethersproject/bignumber'

import { useTranslation } from 'contexts/Localization'
import { wrappedCurrency, wrappedCurrencyAmount } from 'utils/wrappedCurrency'
import { AppDispatch, AppState } from '../index'
import { tryParseAmount } from '../swap/hooks'
import { useCurrencyBalances } from '../wallet/hooks'
import { Field, typeInput } from './actions'


const ZERO = JSBI.BigInt(0)

function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}
function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

export function useMintState(): AppState['mint'] {
  return useSelector<AppState, AppState['mint']>((state) => state.mint)
}

export function useIsTransactionBurnLPs(currencyIn?: Currency): boolean {

  if(currencyIn.name.indexOf("-") > - 1)
    return true

  return false
}


export function useMintActionHandlers(noLiquidity: boolean | undefined): {
  onFieldAInput: (typedValue: string) => void
  onFieldBInput: (typedValue: string) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onFieldAInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ field: Field.CURRENCY_A, typedValue, noLiquidity: noLiquidity === true }))
    },
    [dispatch, noLiquidity],
  )
  const onFieldBInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ field: Field.CURRENCY_B, typedValue, noLiquidity: noLiquidity === true }))
    },
    [dispatch, noLiquidity],
  )

  return {
    onFieldAInput,
    onFieldBInput,
  }
}

export function useDerivedMintInfo(
  currencyA: Currency | undefined,
  currencyB: Currency | undefined,
): {
  dependentField: Field
  currencies: { [field in Field]?: Currency }
  pair?: Pair | null
  pairState: PairState
  currencyBalances: { [field in Field]?: CurrencyAmount }
  parsedAmounts: { [field in Field]?: CurrencyAmount }
  price?: Price
  noLiquidity?: boolean
  liquidityMinted?: TokenAmount
  poolTokenPercentage?: Percent
  error?: string
  comp1?: string
  comp2?: string
} {
  const { library,  account, chainId } = useActiveWeb3React()


  const { t } = useTranslation()

  const { independentField, typedValue, otherTypedValue } = useMintState()

  const dependentField = independentField === Field.CURRENCY_A ? Field.CURRENCY_B : Field.CURRENCY_A

  // tokens
  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.CURRENCY_A]: currencyA ?? undefined,
      [Field.CURRENCY_B]: currencyB ?? undefined,
    }),
    [currencyA, currencyB],
  )

  // getContract(getPriceCalculatordAddress(), zapABI, library)
//  const contractPriceCalculator = new Contract(getPriceCalculatordAddress(), priceCalculatorABI, getProviderOrSigner(library));

  
  const [tokenA1, tokenB1] = [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
  
  const comp1 = useTokenPrice(tokenA1.address);
  const comp2 = useTokenPrice(tokenB1.address);
  // // // TP1 // TP2 console.log((`Pricecalculator 01  comp1 : ${comp1} `)
  // // // TP1 // TP2 console.log((`Pricecalculator 02  comp2 : ${comp2} `)

  

  // pair
  const [pairState, pair] = usePair(currencies[Field.CURRENCY_A], currencies[Field.CURRENCY_B])

  const totalSupply = useTotalSupply(pair?.liquidityToken)

  const noLiquidity = false


  // balances
  // // TP1 // TP2 console.log((`============= useDerivedMintInfo() before useCurrencyBalances ==============`);
  
  const balances = useCurrencyBalances(account ?? undefined, [
    currencies[Field.CURRENCY_A],
    currencies[Field.CURRENCY_B],
  ])
  const currencyBalances: { [field in Field]?: CurrencyAmount } = {
    [Field.CURRENCY_A]: balances[0],
    [Field.CURRENCY_B]: balances[1],
  }

  // amounts
  const independentAmount: CurrencyAmount | undefined = tryParseAmount(typedValue, currencies[independentField])
  

const dependentAmount: CurrencyAmount | undefined = useMemo(() => {
  if (independentAmount) {
    // we wrap the currencies just to get the price in terms of the other token
    const wrappedIndependentAmount = wrappedCurrencyAmount(independentAmount, chainId)
    const [tokenA, tokenB] = [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
    if (tokenA && tokenB && wrappedIndependentAmount) {
      const dependentCurrency = dependentField === Field.CURRENCY_B ? currencyB : currencyA
   

         let   tmpAmount2: BigNumber;
         const BIG_TEN2 = BigNumber.from(10);

         const tmp1Decimal = BIG_TEN2.pow(tokenA.decimals);
         const tmp2Decimal = BIG_TEN2.pow(tokenB.decimals);
         // // // TP1 // TP2 console.log((`IndependentTOkenAmount ${independentAmount.raw}`);

         // // // TP1 // TP2 console.log((`TokenA decimals ${tokenA.decimals}, tokenB decimals ${tokenB.decimals}`);

      
         if (dependentField === Field.CURRENCY_B && comp1 && comp2)
         {
          
        const tmpAmount =  BigNumber.from(independentAmount.raw.toString())

        

     //   tmpAmount2= tmpAmount.mul(comp1).div(comp2).mul(tmp1Decimal).div(tmp2Decimal);
     tmpAmount2= tmpAmount.mul(comp1).div(comp2);

        // // // TP1 // TP2 console.log((`tmpAmount2 pathA ${tmpAmount2}`);

         }
         else if( comp1 && comp2)
         {

          const tmpAmount =  BigNumber.from(independentAmount.raw.toString())

          tmpAmount2= tmpAmount.mul(comp2).div(comp1);

          // // // TP1 // TP2 console.log((`tmpAmount2 pathB`);

         }


      let tmpTokenAmount01;

      if (currencies[dependentField] instanceof Token && tmpAmount2)
      {
      tmpTokenAmount01 = new TokenAmount(currencies[dependentField] as Token, JSBI.BigInt(tmpAmount2.toString()))

    //  // // TP1 // TP2 console.log((`branch 1`);

      }
      else if (tmpAmount2)
      {
      
        tmpTokenAmount01 = CurrencyAmount.ether(tmpAmount2.toString())

      //  // // TP1 // TP2 console.log((`branch 2`);
      
      }
      
    //  // // TP1 // TP2 console.log((`mint zap tmpTokenAmount ${tmpAmount2} ${tmpTokenAmount01.toFixed(2)}`);

      const dependentTokenAmount = tmpTokenAmount01 ;
    

//         // // // TP1 // TP2 console.log((`Dependent TokenAMount ${dependentTokenAmount.raw}`)


   
          return dependentCurrency === ETHER ? CurrencyAmount.ether(dependentTokenAmount.raw) : dependentTokenAmount
    }
    return undefined
  }
  return undefined


}, [comp1, comp2, dependentField, independentAmount, currencyA, chainId, currencyB, currencies])


  const parsedAmounts: { [field in Field]: CurrencyAmount | undefined } = useMemo(
    () => ({
      [Field.CURRENCY_A]: independentField === Field.CURRENCY_A ? independentAmount : dependentAmount,
      [Field.CURRENCY_B]: independentField === Field.CURRENCY_A ? dependentAmount : independentAmount,
    }),
    [dependentAmount, independentAmount, independentField],
  )

 
  const price = useMemo(() => {
    const { [Field.CURRENCY_A]: currencyAAmount, [Field.CURRENCY_B]: currencyBAmount } = parsedAmounts
    
    if (currencyAAmount && currencyBAmount && comp2 && comp1) {
      return new Price(currencyAAmount.currency, currencyBAmount.currency, JSBI.BigInt(comp2.toString()), JSBI.BigInt(comp1.toString()))
    } 
    return undefined
  
  }, [comp1, comp2, parsedAmounts])

  const liquidityMinted = undefined
  const poolTokenPercentage = undefined




  let error: string | undefined
  if (!account) {
    error = t('Connect Wallet')
  }




  if (pairState === PairState.INVALID) {
    error = error ?? t('Invalid pair')
  }

 // if (!parsedAmounts[Field.CURRENCY_A] || !parsedAmounts[Field.CURRENCY_B]) {
  if (!parsedAmounts[Field.CURRENCY_A] ) {
     
 error = error ?? t('Enter an amount')
  }

  const { [Field.CURRENCY_A]: currencyAAmount, [Field.CURRENCY_B]: currencyBAmount } = parsedAmounts

  
  if (currencyAAmount && currencyBalances?.[Field.CURRENCY_A]?.lessThan(currencyAAmount)) {
    error = t('Insufficient %symbol% balance', { symbol: currencies[Field.CURRENCY_A]?.symbol })
  }

 // const newComp1 = comp1.div(tmp1Decimal);
 // const newComp2 = comp1.div(tmp2Decimal);
 const newComp1 = useTokenPriceDecimals(tokenA1.address);
 const newComp2 = useTokenPriceDecimals(tokenB1.address);
  

  return {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
    comp1: newComp1,
    comp2: newComp2
  }
}

 import { BigNumber } from '@ethersproject/bignumber'
 import {BigNumber as BigNumber2}  from 'bignumber.js'

import { Token, TokenAmount, ETHER} from '@pancakeswap/sdk'
import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { getCakeContract, getPriceCalculatorContract } from 'utils/contractHelpers'
import { getPriceCalculatordAddress } from 'utils/addressHelpers'
import { BASE_URL, VAULTAPI_URL } from 'config'

import { fetchBuilder, MemoryCache } from 'node-fetch-cache';
import { BIG_ONE, BIG_TEN, BIG_ZERO } from 'utils/bigNumber'
import useRefresh from './useRefresh'
import { useTokenContract } from './useContract'
import { useSingleCallResult } from '../state/multicall/hooks'
import { useToken } from './Tokens'


// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export const useTokenPrice = (tokenAddress: string) => {
    
   // // TP1 // TP2 console.log((`========= useTokenPrice()    ${tokenAddress}`);

   const { slowRefresh } = useRefresh();
   
    const [tokenPrice, setTokenPrice] = useState<BigNumber>(BigNumber.from(0));

   
    let tmpToken;
    let isETH = false;
    let tokenAddress2=''

    if(tokenAddress === '0x0000000000000000000000000000000000000000')
      {  
        tokenAddress2 = '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1';
        isETH=true;

      
      }
      else 
        tokenAddress2 = tokenAddress;

    tmpToken = useToken(tokenAddress2);

    if(isETH)
      tmpToken = ETHER;
  
  
   // // // TP1 // TP2 console.log((`START ================= useTokenPrice ${tokenAddress} =============`);
   
    useEffect(() => {
      async function fetchTokenPrice() {
        const vaultPriceURL=`${VAULTAPI_URL}/lps`
        const wei1 = ethers.utils.parseEther("1.0");
        // // // TP1 // TP2 console.log((`Async Function fetchTokenPrice`)
        // // TP1 // TP2 console.log((`===================== useTokenPrice PCContract call ===================`);
     

        // // // TP1 // TP2 console.log((`tokenAddress ${tokenAddress} tmpToken ${tmpToken.address}`);

        // // TP1 // TP2 console.log((`TokenPrice ${tokenAddress} `);

     
        // Importan Change Fix
       // if(!tokenAddress || !(tmpToken.address === tokenAddress))
       if(!tokenAddress )
     
       {
          setTokenPrice(BigNumber.from(0));

       //  // // TP1 // TP2 console.log(("return=========================================useTokenPrice return");

          return
        }

        
    //    const priceCalculatorContract = getPriceCalculatorContract(getPriceCalculatordAddress());
    //    const supply = await priceCalculatorContract.valueOfAsset(tokenAddress, wei1);

        let tmpPrice = new BigNumber2(1);

        
        const mulDec = 18- Number(tmpToken.decimals);


        try{
        
        await fetch(vaultPriceURL, {mode:'cors'})
               .then((response) => { return response.json() })
               .then((data) => {
               //  // // TP1 // TP2 console.log(("Remote url APYs")
               //  // // TP1 // TP2 console.log((data);
               // const tmpChainTVL = data["56"] ?? "";
           //     tmp2LPTokenPrice = new BigNumber(data[farm.lpAddresses[56]]).multipliedBy(BIG_TEN.pow(18).toString());
           

           tmpPrice = (new BigNumber2(data[tokenAddress]).multipliedBy(BIG_TEN.pow(18))).decimalPlaces(0,1);
      
           tmpPrice = tmpPrice.multipliedBy(BIG_TEN.pow(mulDec));
             // tmpPrice = new BigNumber2(data[tokenAddress]).decimalPlaces;

            
                //     tmp2RewardTokenPrice = new BigNumber(data[farm.vaultRewardTokenAddress]).multipliedBy(BIG_TEN.pow(farm.vaultRewardTokenDecimal));
                
    
    
             //   // // TP1 // TP2 console.log((`tmpChainTVL ${farm.pid} ${tmpChainTVL}`);
                 
               // tmpPoolTVL = tmpChainTVL[farm.pid] ?? "10";
        
              //  // // TP1 // TP2 console.log((`tmpPoolTVL ${farm.pid} ${tmpPoolTVL}`);
        
               //  // // TP1 // TP2 console.log((`mytmpRewardAPR : ${mytmpRewardAPR}`)
               })
         }
          catch(err){
               // // TP1 // TP2 console.log(('there was an error fetching TVL', err);
           }
        
    
           // // // TP1 // TP2 console.log((`useTokenPrice ${tmpPrice.toString()}`);
  
           // // TP1 // TP2 console.log((`tmpPrice ${tmpPrice} ${tokenAddress}`)
           const tmp2 = BigNumber.from(tmpPrice.toString());


           // // // TP1 // TP2 console.log((`converted Big Num ${tmp2}`);
       //    */
        //   // // TP1 // TP2 console.log((`supply ============================== ${supply[1]}`);


    //    setTokenPrice(supply[1]);
     setTokenPrice(tmp2);

    
    }
  
      fetchTokenPrice()

    }, [slowRefresh, tokenAddress,  tmpToken.decimals, tmpToken.address])
  
 // }, [slowRefresh, tokenAddress])
  

 // // // TP1 // TP2 console.log((`return token ${tokenAddress} price ${tokenPrice}`)
    return tokenPrice
  }
  
import { MenuItemsType, DropdownMenuItemType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
// import { nftsBaseUrl } from 'views/Nft/market/constants'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Farm'),
    icon: 'Earn',
    href: '/Farms',
    showItemsOnMobile: false,
    items: []
  },
  {
    label: t('Zap'),
    icon: 'Trade',
    href: '/add/ETH/0x82670f35306253222F8a165869B28c64739ac62e',
    showItemsOnMobile: false,
    items: []
  },
  {
    label: t('About'),
    href: '/About',
    icon: 'Trophy',
    showItemsOnMobile: false,
    items: [
    
    ],
  },
  
  
 
]

export default config

import React, { useCallback, useEffect, useState } from 'react'
import JSBI from 'jsbi';


import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { Currency, ETHER, TokenAmount} from '@pancakeswap/sdk'
import { Button, Text, Flex, ArrowDownIcon, CardBody, Message, useModal,  AutoRenewIcon } from '@pancakeswap/uikit'
import { RouteComponentProps } from 'react-router-dom'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../state'
import { LightCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Layout/Column'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModalZap'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { DoubleCurrencyLogo } from '../../components/Logo'
import { AppHeader, AppBody } from '../../components/App'
import Row, { RowBetween } from '../../components/Layout/Row'
import ConnectWalletButton from '../../components/ConnectWalletButton'

import { ZAP_ADDRESS } from '../../config/constants'
import { PairState } from '../../hooks/usePairs'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { Field, resetMintState } from '../../state/mint/actions'
import { useDerivedMintInfo, useMintActionHandlers, useMintState, useIsTransactionBurnLPs} from '../../state/mint/hooks'


import { useTransactionAdder } from '../../state/transactions/hooks'
import { useGasPrice, useIsExpertMode } from '../../state/user/hooks'
import { getZapContract } from '../../utils'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import ConfirmAddModalBottom from './ConfirmAddModalBottom'
import { currencyId } from '../../utils/currencyId'
import PoolPriceBar from './PoolPriceBar'
import Page from '../Page'



export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB },
  },
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const { account, chainId, library } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const gasPrice = useGasPrice()
  const GAS_LIMIT=4000000

  // // // TP1 // TP2 console.log((`Zap index.ts AddLiquidity() currencyIDA ${currencyIdA} B: ${currencyIdB}`);


  const currencyA = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)

  useEffect(() => {
    if (!currencyIdA && !currencyIdB) {
      dispatch(resetMintState())
    }
  }, [dispatch, currencyIdA, currencyIdB])

  // Comment out unused
  /*
  const oneCurrencyIsWETH = Boolean(
    chainId &&
      ((currencyA && currencyEquals(currencyA, WETH[chainId])) ||
        (currencyB && currencyEquals(currencyB, WETH[chainId]))),
  )
*/
  const expertMode = useIsExpertMode()

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
    comp1,
    comp2
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

  const isValid = !error

  // // // TP1 // TP2 console.log((`isValid ${isValid}`);
  

  // modal and loading
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  // const deadline = useTransactionDeadline() // custom from users settings
  // const [allowedSlippage] = useUserSlippageTolerance() // custom from users
  const [txHash, setTxHash] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(10) ?? '',
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field]),
      }
    },
    {},
  )

  const atMaxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      }
    },
    {},
  )

  // check whether the user has approved the router on the tokens
  // // // TP1 // TP2 console.log((`============ Zap()  ${new Date()} checking approvals of ${JSON.stringify(parsedAmounts[Field.CURRENCY_A])} `);
  const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], ZAP_ADDRESS)
  // const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], ZAP_ADDRESS)
  // // TP1 // TP2 console.log((`============= approvalA() ${new Date()} ${JSON.stringify(approvalA)}`);
 
  const addTransaction = useTransactionAdder()

  async function onAdd() {
    if (!chainId || !library || !account) return
    // const router = getRouterContract(chainId, library, account)
    const zap = getZapContract(chainId, library, account)


    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
  //  if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
    
    if (!parsedAmountA ||  !currencyA ) {    
        return
      }

    // Slippage not used
    /*
    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? 0 : allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? 0 : allowedSlippage)[0],
    }
    */

     let method: (...args: any) => Promise<TransactionResponse>
     let args: Array<string | string[] | number>
     let value: BigNumber | null

  
   // // // TP1 // TP2 console.log(('Executing Zap');

    if (currencyA === ETHER ) {
      
      // // // TP1 // TP2 console.log((`Zap CurrencyA is Ether method branch`);

      const a = JSBI.multiply(parsedAmountB.raw, JSBI.BigInt(95));
      const b = JSBI.divide(a, JSBI.BigInt(100));

      method = zap.zapInLimit
      args = [
        wrappedCurrency(currencyB, chainId).address,
        b.toString(),
      ]
      value = BigNumber.from((parsedAmountA).raw.toString())

     // console.log("================ ZapIn ===========================");

     // console.log(`b: ${b.toString()}`);
    
    } 
    else if (currencyB === ETHER || (currencyA.symbol.indexOf("-") > -1))
    {
      let b = JSBI.BigInt(0);

      // // // TP1 // TP2 console.log((`Zap CurrencyB is Ether method branch`);
    if(currencyB === ETHER &&  !(currencyA.symbol.indexOf("-") > -1 ) )
    {
    
      const a = JSBI.multiply(parsedAmountB.raw, JSBI.BigInt(95));
       b = JSBI.divide(a, JSBI.BigInt(100));
     // console.log(`parsedAmountB ${ (parsedAmountB).raw.toString()}`);
    
    }
      else
     { 
      b = JSBI.BigInt(0);

     // console.log(`parsedAmountB 0000000000000`);
  }
      
      method = zap.zapOutLimit
      args = [
        wrappedCurrency(currencyA, chainId).address,
        parsedAmountA.raw.toString(),
        b.toString(),
       
      ]
      value = null

      // console.log("================ ZapOut ===========================");
      
      // console.log(`b: ${b.toString()}`);


    }
    else
    {
      // // // TP1 // TP2 console.log((`Zap Token To Token method branch`);
      const a = JSBI.multiply(parsedAmountB.raw, JSBI.BigInt(95));
      const b = JSBI.divide(a, JSBI.BigInt(100));

      method = zap.zapInTokenLimit
      args = [
        wrappedCurrency(currencyA, chainId).address,
        parsedAmountA.raw.toString(),
        wrappedCurrency(currencyB, chainId).address,
        b.toString()
       
      ]
      value = null
      // console.log("================ ZapInToken ===========================");
     
      // console.log(`b: ${b.toString()}`);
     

    }

    // const value = BigNumber.from(parsedAmountA.raw.toString())

    // const method=zap.zapIn


    setAttemptingTxn(true);


     await   method(...args, {

          ...(value ? { value } : {}),
          gasLimit: GAS_LIMIT,

          gasPrice,
        }).then((response) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary: `Zapped ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
              currencies[Field.CURRENCY_A]?.symbol
            } To  ${currencies[Field.CURRENCY_B]?.symbol}`,
          })

          setTxHash(response.hash);

          // library.getTransactionReceipt(response.hash);
          
        })
      
      
      .catch((err) => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (err?.code !== 4001) {
          console.error(err)
        }
      }).finally(() => {

       
       // dispatch(resetMintState());
        setAttemptingTxn(false)
      }
      )

     

  }


  // // // TP1 // TP2 console.log((`Attempting txn ${attemptingTxn}`);



  
  const modalHeader = () => {
    return noLiquidity ? (
      <Flex alignItems="center">
        <Text fontSize="48px" marginRight="10px">
          {`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol}`}
        </Text>
        <DoubleCurrencyLogo
          currency0={currencies[Field.CURRENCY_A]}
          currency1={currencies[Field.CURRENCY_B]}
          size={30}
        />
      </Flex>
    ) : (
      <AutoColumn>
        <Flex alignItems="center">
          <Text fontSize="48px" marginRight="10px">
            {liquidityMinted?.toSignificant(6)}
          </Text>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={30}
          />
        </Flex>
        <Row>
          <Text fontSize="24px">
            {`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol} Pool Tokens`}
          </Text>
        </Row>
        <Text small textAlign="left" my="24px">
          {t('Output is estimated. ')}
        </Text>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    )
  }

  const pendingTextBeta = t('Converting %amountA% %symbolA% to %amountB% %symbolB%', {
    amountA: parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    symbolA: currencies[Field.CURRENCY_A]?.symbol ?? '',
    amountB: parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
    symbolB: currencies[Field.CURRENCY_B]?.symbol ?? '',
  })

  const tmpSymbolA= currencies[Field.CURRENCY_A]?.symbol ?? ''
  // If zapping from LP tokens change the message
  const pendingText = ( tmpSymbolA.indexOf("-") > -1) ? t(`Redeeming %amountA% %symbolA% `
  , {
    amountA: parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    symbolA: currencies[Field.CURRENCY_A]?.symbol ?? '',
  }
  
  ) : pendingTextBeta
  // burnLP=false;


  const handleCurrencyASelect = useCallback(
    (currencyA_: Currency) => {

      // console.debug(`handleCurrencyASelect newCurrencyA ${currencyA_.name}`);


      const newCurrencyIdA = currencyId(currencyA_)
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/add/${currencyIdB}/${currencyIdA}`)
      }
      else if (currencyA_.name.indexOf('-') > -1)
      {
        history.push(`/add/${newCurrencyIdA}/ETH`)
    

      } 
      else if (currencyIdB) {
        history.push(`/add/${newCurrencyIdA}/${currencyIdB}`)
      }
     
      else {
        history.push(`/add/${newCurrencyIdA}`)
      }
    },
    [currencyIdB, history, currencyIdA],
  )
  const handleCurrencyBSelect = useCallback(
    (currencyB_: Currency) => {
      console.debug(`handleCurrencyBSelect newCurrencyB ${currencyB_.name}`);

      const newCurrencyIdB = currencyId(currencyB_)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/add/${currencyIdB}/${newCurrencyIdB}`)
        } else {
          history.push(`/add/${newCurrencyIdB}`)
        }
      } else {
        history.push(`/add/${currencyIdA || 'ETH'}/${newCurrencyIdB}`)
      }
    },
    [currencyIdA, history, currencyIdB],
  )

  const handleDismissConfirmation = useCallback(() => {
    // if there was a tx hash, we want to clear the input
    // // // TP1 // TP2 console.log((`On dimiss txHash ${txHash}`)
    if (txHash) {
      onFieldAInput('')
    }
    setTxHash('')
  }, [onFieldAInput, txHash])

  const addIsUnsupported = useIsTransactionUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

  const burnLP = useIsTransactionBurnLPs(currencies?.CURRENCY_A)


  const [onPresentAddLiquidityModal] = useModal(
    <TransactionConfirmationModal
      title='You will be converting your supplied tokens to your target tokens'
      customOnDismiss={handleDismissConfirmation}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={() => <ConfirmationModalContent topContent={modalHeader} bottomContent={modalBottom} />}
      pendingText={pendingText}
      currencyToAdd={pair?.liquidityToken}
    />,
    true,
    true,
    'addLiquidityModal',
  )

  return (
    <Page>
      <AppBody>
        <AppHeader
          title={t('Zap Tokens')}
          subtitle={t('Convenience function to help you get LP tokens. Zap in small increments of <10 ETH')}
          helper={t(
            'If you are sensitive to slippage and or wish to zap in large amounts please use the related DEX page directly',
          )}
          backTo="/"
        />
        <CardBody>
          <AutoColumn gap="20px">
            {noLiquidity && (
              <ColumnCenter>
                <Message variant="warning">
                  <div>
                    <Text bold mb="8px">
                      {t('Unable to get accurate price')}
                    </Text>
                    <Text mb="8px">{t('ZAP is able to convert your tokens however it is unable to accurately estimate how much you will receive')}</Text>
                    </div>
                </Message>
              </ColumnCenter>
            )}
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_A]}
              onUserInput={onFieldAInput}
              onMax={() => {
                onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
              }}
              onCurrencySelect={handleCurrencyASelect}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
              currency={currencies[Field.CURRENCY_A]}
              id="add-liquidity-input-tokena"
              showCommonBases
              
              
            />
            <ColumnCenter>
              <ArrowDownIcon width="16px" />
            </ColumnCenter>

            { burnLP ?  ( 

              <Text>LPs will be burned and redeemed back to their pairing parts</Text>
            ) : (  
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onFieldBInput}
              onCurrencySelect={handleCurrencyBSelect}
              onMax={() => {
                onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
              }}
              showMaxButton={false}
              currency={currencies[Field.CURRENCY_B]}
              id="add-liquidity-input-tokenb"
              
              // showCommonBases
              label="To (Estimate)"
              
                    

            />
             ) }
            {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
              <>
                <LightCard padding="0px" borderRadius="20px">
                  <RowBetween padding="1rem">
                    <Text fontSize="14px">
                      {noLiquidity ? t('Initial prices') : t('Prices')}
                    </Text>
                  </RowBetween>{' '}
                  <LightCard padding="1rem" borderRadius="20px">
                    <PoolPriceBar
                      currencies={currencies}
                      poolTokenPercentage={poolTokenPercentage}
                      noLiquidity={noLiquidity}
                      price={price}
                      comp1={comp1}
                      comp2={comp2}
                    />
                  </LightCard>
                </LightCard>
              </>
            )}

            {addIsUnsupported ? (
              <Button disabled mb="4px">
                {t('Unsupported Asset')}
              </Button>
            ) : !account ? (
              <ConnectWalletButton />
            ) : (
              <AutoColumn gap="md">
                {(approvalA === ApprovalState.UNKNOWN && isValid) ? ( 
                  <Flex>
                  <Text>Checking Token approval</Text> <AutoRenewIcon spin color="currentColor" />
                  </Flex>
                 ) : (   <Text/>)}


                {(approvalA === ApprovalState.NOT_APPROVED ||
                  approvalA === ApprovalState.PENDING ) &&
                  isValid && (
                    <RowBetween>

                    
                        
                      <Flex width='100%'>

                  

                    { (approvalA === ApprovalState.PENDING) ? (   

                        <Button
                          onClick={approveACallback}
                          isLoading
                          width='100%'
                          endIcon={<AutoRenewIcon spin color="currentColor" />}
                        >
                        {t('Enabling %asset%', { asset: currencies[Field.CURRENCY_A]?.symbol })}
                       
                        </Button>

                    ) : (

                      <Button
                      onClick={approveACallback}
                     
                     // width={approvalB !== ApprovalState.APPROVED ? '48%' : '100%'}
                      width='100%'
                    
                      >

                       {t('Enable %asset%', { asset: currencies[Field.CURRENCY_A]?.symbol })} 

                     
                    </Button> 

                    )}
                      
                        </Flex>      

                    
                     
                    </RowBetween>


                  )}
                <Button
                  variant={
                    !isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]
                      ? 'danger'
                      : 'primary'
                  }
                  onClick={() => {
                    if (expertMode) {
                      onAdd()
                    } else {
                      onPresentAddLiquidityModal()
                    }
                  }}
                // Don't need Approval if we are zapping from BNB only
                  disabled={!isValid || approvalA !== ApprovalState.APPROVED}
                >
                  {error ?? t('Zap')}
                </Button>
              </AutoColumn>
            )}
          
          </AutoColumn>



        </CardBody>
      </AppBody>
          <AutoColumn style={{ minWidth: '20rem', width: '100%', maxWidth: '400px', marginTop: '1rem' }}>
            <LightCard>
           
            <Text fontSize="14px" style={{ textAlign: 'center' }}>
            <span role="img" aria-label="pancake-icon">
            ❇️
            </span>{' '}
            {t(
              "(Attention) !!! Displayed prices and output amount are estimates only, actual result will depend on the on-chain liquidity at execution time",
            )}
          </Text>
            
            <Text fontSize="14px" style={{ textAlign: 'center' }}>
            <span role="img" aria-label="pancake-icon">
            ❇️
            </span>{' '}
            {t(
              "Selecting LP tokens as the input source will burn and decompose it to the original pairing or for Curve LPs, the quote token, regardless of the target token you choose",
            )}
          </Text>
        
          <Text fontSize="14px" style={{ textAlign: 'center' }}>
            <span role="img" aria-label="pancake-icon">
            ❇️
            </span>{' '}
            {t(
              "This Zap is provided as a free service, Hippo charges no fees on top of the underlying DEXes swap fees",
            )}
          </Text>
       
        </LightCard>  </AutoColumn>
    </Page>
  )
}

import React, { lazy } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { ResetCSS } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import useUserAgent from 'hooks/useUserAgent'
import useScrollOnRouteChange from 'hooks/useScrollOnRouteChange'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { usePollBlockNumber } from 'state/block/hooks'

import { useFetchProfile } from 'state/profile/hooks'
import { DatePickerPortal } from 'components/DatePicker'
// import { nftsBaseUrl } from 'views/Nft/market/constants'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import EasterEgg from './components/EasterEgg'
import GlobalCheckClaimStatus from './components/GlobalCheckClaimStatus'
import history from './routerHistory'
// Views included in the main bundle
// import Swap from './views/Swap'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './views/Zap/redirects'

import {
  RedirectCalcDuplicateTokenIds,
  RedirectOldCalcLiquidityPathStructure,
  RedirectToCalcLiquidity,
} from './views/Calc/redirects'

import {

RedirectToDefaultReferral

} from './views/Referral/redirects'

// import RedirectOldRemoveLiquidityPathStructure from './views/RemoveLiquidity/redirects'
// import { RedirectPathToSwapOnly, RedirectToSwap } from './views/Swap/redirects'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const NotFound = lazy(() => import('./views/NotFound'))
const About = lazy(() => import('./views/About'))
const AddLiquidity = lazy(() => import('./views/Zap'))
const CalcLiquidity = lazy(() => import('./views/Calc'))

const Referral = lazy(() => import('./views/Referral'))


// const ProfileCreation = lazy(() => import('./views/ProfileCreation'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account } = useWeb3React()

   usePollBlockNumber()
   useEagerConnect()
 //  useFetchProfile()
  usePollCoreFarmData()
  useScrollOnRouteChange()
  useUserAgent()

  const datenow = Math.floor(Date.now())
          // // // TP1 // TP2 console.log((`App Refresh ${datenow}`)
        return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <GlobalCheckClaimStatus excludeLocations={[]} />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Farms />
            </Route>
            <Route path="/history" exact>
              <Farms />
            </Route>
            

            <Route path="/Farms" >
              <Farms />
            </Route>
           
            <Route path="/About">
              <About />
            </Route>

            <Route path="/Referral/:referralID" component={Referral} />
            <Route path="/Referral" component={RedirectToDefaultReferral} />

            {/* NFT */}
      

            {/* Info pages */}
      
            {/* Using this format because these components use routes injected props. We need to rework them with hooks */}
            {/* Redirect */}

            {/* 404 */}
            <Route exact path="/calc" component={CalcLiquidity} />
            <Route exact path="/calc/:currencyIdA" component={RedirectOldCalcLiquidityPathStructure} />
            <Route exact path="/calc/:currencyIdA/:currencyIdB" component={RedirectCalcDuplicateTokenIds} />
            
            <Route exact path="/add" component={AddLiquidity} />
            <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
           
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <EasterEgg iterations={2} />
      <ToastListener />
      <DatePickerPortal />
    </Router>
  )
}

export default React.memo(App)

import React from 'react'
// import { ModalProvider, light1, dark1 } from '@pancakeswap/uikit'
import { ModalProvider, 
   light as lightBase,
  dark as darkBase,
  PancakeTheme
} from '@pancakeswap/uikit'

import { Web3ReactProvider } from '@web3-react/core'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { useThemeManager } from 'state/user/hooks'
import { getLibrary } from 'utils/web3React'
import { LanguageProvider } from 'contexts/Localization'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { ToastsProvider } from 'contexts/ToastsContext'
import store from 'state'

// Roi editor uses gradient gold theme
const light: PancakeTheme = lightBase;

light.colors.text = '#000000';
light.colors.input='#DDDDDD'
light.colors.inputSecondary='#F26B1D'

light.colors.primary='#c9b8fe'
light.colors.primaryBright='#d1c2ff'
light.colors.primaryDark='#765ebf'
light.colors.secondary="#F26B1D";
light.colors.tertiary='#999999'

light.colors.background='#bbd3ff'
light.colors.backgroundAlt='#bbd3ff'
light.colors.overlay="#000000"

light.colors.textDisabled='#CCCCCC'
light.colors.textSubtle='#666666'
light.colors.invertedContrast=`#FFFFFF`
light.colors.cardBorder = '#FFFFFF'

light.card.boxShadow = '0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05)'
light.card.boxShadowActive ='0px 0px 0px 1px #0098A1, 0px 0px 4px 8px rgba(31, 199, 212, 0.4)'
light.card.dropShadow="drop-shadow(0px 1px 4px rgba(25, 19, 38, 0.15))"
light.card.background = '#ffffe3';
light.card.cardHeaderBackground = {
  default: 'black',
  violet: 'red',
  blue: 'red',
  bubblegum: 'black'
};

light.colors.gradients.bubblegum = 'linear-gradient(158deg, rgba(255,255,255,1) 0%, rgba(188,211,255,1) 28%);'
// light.colors.gradients.bubblegum = '#FF0000'

light.colors.gradients.violet='radial-gradient(circle, rgba(212,236,191,0.7371323529411764) 0%, rgba(188,211,255,1) 34%);'
light.colors.gradients.violetAlt=`#FF0000`
light.colors.gradients.cardHeader=`#FFFFFF`
light.modal.background=`#ffffe3`

light.mediaQueries.nav='#000000'

light.nav.background='#FFFFFF'


// customize dark theme
const dark: PancakeTheme = darkBase;

dark.colors.text = '#CCCCCC';
dark.colors.secondary = '#BBBBBB'
dark.colors.inputSecondary='#EEEEEE'

dark.colors.primary='#9c9c9c'
dark.colors.primaryBright='#f0b90b'
dark.colors.primaryDark='#333333'
dark.colors.secondary = '#BBBBBB'
dark.colors.tertiary='#444444'

dark.colors.background='#000000'
dark.colors.backgroundAlt='#000000'
dark.colors.overlay="#000000"

dark.colors.textDisabled='#CCCCCC'
dark.colors.textSubtle='#f0b90b'
dark.colors.invertedContrast='#FFFFFF'
dark.colors.cardBorder = '#CCCCCC'

dark.card.boxShadow = '0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05)'
dark.card.boxShadowActive ='0px 0px 0px 1px #0098A1, 0px 0px 4px 8px rgba(31, 199, 212, 0.4)'
dark.card.dropShadow="drop-shadow(0px 1px 4px rgba(25, 19, 38, 0.15))"
dark.card.background = '#000000';
dark.card.cardHeaderBackground = {
  default: 'black',
  violet: 'red',
  blue: 'red',
  bubblegum: 'black'
};

dark.colors.gradients.bubblegum = '#000000'
dark.colors.gradients.violet='#000000'
dark.colors.gradients.violetAlt=`#FF0000`
dark.colors.gradients.cardHeader=`#000000`
dark.modal.background=`#000000`

dark.mediaQueries.nav='#000000'

dark.nav.background='#000000'

const ThemeProviderWrapper = (props) => {
  const [isDark] = useThemeManager()
  return <ThemeProvider theme={isDark ? dark : light} {...props} />
}

const Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <ToastsProvider>
          <HelmetProvider>
            <ThemeProviderWrapper>
              <LanguageProvider>
                <RefreshContextProvider>
                  <ModalProvider>{children}</ModalProvider>
                </RefreshContextProvider>
              </LanguageProvider>
            </ThemeProviderWrapper>
          </HelmetProvider>
        </ToastsProvider>
      </Provider>
    </Web3ReactProvider>
  )
}

export default Providers

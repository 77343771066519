import { BigNumber } from '@ethersproject/bignumber'

import { Token, TokenAmount } from '@pancakeswap/sdk'
import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { getCakeContract, getPriceCalculatorContract } from 'utils/contractHelpers'
import { getPriceCalculatordAddress } from 'utils/addressHelpers'

import {BigNumber as BigNumber2}  from 'bignumber.js'
import { BIG_ONE, BIG_TEN, BIG_ZERO } from 'utils/bigNumber'
import { BASE_URL, VAULTAPI_URL } from 'config'
import { useSingleCallResult } from '../state/multicall/hooks'

import { useTokenContract } from './useContract'

import useRefresh from './useRefresh'
import { useToken } from './Tokens'



// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export const useTokenPrice = (tokenAddress: string) => {
    const { fastRefresh, slowRefresh } = useRefresh()
    const [tokenPrice, setTokenPrice] = useState<BigNumber>()
    const tmpToken = useToken(tokenAddress);

     // // TP1 // TP2 console.log(("START ================= useTokenPriceFast =============");
    
   
    useEffect(() => {
      async function fetchTokenPrice() {
        
        // // // TP1 // TP2 console.log((`Async Function fetchTokenPrice`)
        const wei1 = ethers.utils.parseEther("1.0");
        const vaultPriceURL=`${VAULTAPI_URL}/lps`
    
   //     // // TP1 // TP2 console.log((`tokenAddress ${tokenAddress} tmpToken ${tmpToken.address}`);
    
       // Importan Change Fix
      //  if(!tokenAddress || !(tmpToken.address === tokenAddress))
      if(!tokenAddress)
      {
          setTokenPrice(BigNumber.from(0));

          return
        }

        const priceCalculatorContract = getPriceCalculatorContract(getPriceCalculatordAddress());
        const supply = await priceCalculatorContract.valueOfAsset(tokenAddress, wei1);
        
   //     setTokenPrice(supply[1]);
      /*  let tmpPrice;
        try{
          await fetch(vaultPriceURL, {mode:'cors'})
               .then((response) => { return response.json() })
               .then((data) => {
               //  // // TP1 // TP2 console.log(("Remote url APYs")
               //  // // TP1 // TP2 console.log((data);
               // const tmpChainTVL = data["56"] ?? "";
           //     tmp2LPTokenPrice = new BigNumber(data[farm.lpAddresses[56]]).multipliedBy(BIG_TEN.pow(18).toString());
           // Need to change to use tokeninfo
           tmpPrice = (new BigNumber2(data[tokenAddress]).multipliedBy(BIG_TEN.pow(18))).decimalPlaces(0,1);

             
                //     tmp2RewardTokenPrice = new BigNumber(data[farm.vaultRewardTokenAddress]).multipliedBy(BIG_TEN.pow(farm.vaultRewardTokenDecimal));
                
    
    
             //   // // TP1 // TP2 console.log((`tmpChainTVL ${farm.pid} ${tmpChainTVL}`);
                 
               // tmpPoolTVL = tmpChainTVL[farm.pid] ?? "10";
        
              //  // // TP1 // TP2 console.log((`tmpPoolTVL ${farm.pid} ${tmpPoolTVL}`);
        
               //  // // TP1 // TP2 console.log((`mytmpRewardAPR : ${mytmpRewardAPR}`)
               })
         }
          catch(err){
               // // TP1 // TP2 console.log(('there was an error fetching TVL', err);
           }
        
    

           // // TP1 // TP2 console.log((`useTokenPriceFast ${tmpPrice.toString()}`);
  
           //   setTokenPrice(supply[1]);
     const tmp2 = BigNumber.from(tmpPrice.toString());

     // // TP1 // TP2 console.log((`converted Big Num ${tmp2}`);
     // // TP1 // TP2 console.log((`supply ${supply}`);
           

*/

   setTokenPrice(supply[1]);
// setTokenPrice(tmp2);


    }
  
      fetchTokenPrice()

   // }, [fastRefresh, tokenAddress, tmpToken.address, tmpToken.decimals])
  }, [fastRefresh, tokenAddress])
  
    return tokenPrice
  }
  
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import farmsConfig from 'config/constants/farms'
import isArchivedPid from 'utils/farmHelpers'
import priceHelperLpsConfig from 'config/constants/priceHelperLps'

import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber'
import BigNumber from 'bignumber.js'
import { VAULTAPI_URL } from 'config'


import fetchFarms from './fetchFarms'
import fetchFarmsPrices from './fetchFarmsPrices'
import {
  fetchFarmUserEarnings,
  fetchFarmUserAllowances,
  fetchFarmUserTokenBalances,
  fetchFarmUserStakedBalances,
} from './fetchFarmUser'
import { SerializedFarmsState, SerializedFarm } from '../types'




const noAccountFarmConfig = farmsConfig.map((farm) => ({
  ...farm,
  userData: {
    allowance: '0',
    tokenBalance: '0',
    stakedBalance: '0',
    earnings: '0',
    depositedAt: '0',
    feeDuration: '0',
    feePercentage: '0'
  },
}))

const initialState: SerializedFarmsState = {
  data: noAccountFarmConfig,
  loadArchivedFarmsData: false,
  userDataLoaded: false,
  totalTVL: "0",

}

const tmpTotalTVL: BigNumber = new BigNumber("0");




export const nonArchivedFarms = farmsConfig.filter(({ pid }) => !isArchivedPid(pid))

// Async thunks
export const fetchFarmsPublicDataAsync = createAsyncThunk<SerializedFarm[], number[]>(
  'farms/fetchFarmsPublicDataAsync',
  async (pids) => {
    const farmsToFetch = farmsConfig.filter((farmConfig) => pids.includes(farmConfig.pid))

    // // // TP1 // TP2 console.log((`fetchFarmpublicdataAsync`);

    // TODOBEAR fetching farm prices
    // Need to change this to use Pricecalculator instead of maintaining extensive farms list

    // Add price helper farms
    // const farmsWithPriceHelpers = farmsToFetch.concat(priceHelperLpsConfig)
    const farmsWithPriceHelpers = farmsToFetch;


    // // // TP1 // TP2 console.log((`${JSON.stringify(farmsWithPriceHelpers)}`);
    // // // TP1 // TP2 console.log((`fetchFarmpublicdataAsync farmsWithPriceHelpers`);
    const farms = await fetchFarms(farmsWithPriceHelpers)
    // // // TP1 // TP2 console.log((`fetchFarmpublicdataAsync farmswithPrices`);

    const farmsWithPrices = await fetchFarmsPrices(farms)
    // // // TP1 // TP2 console.log((`fetchFarmpublicdataAsync farmswithPrices finished`);


    // Filter out price helper LP config farms
    const farmsWithoutHelperLps = farmsWithPrices.filter((farm: SerializedFarm) => {

    //   // // TP1 // TP2 console.log((`Farm without helperLPs newAPR : ${farm.newAPR}`);

      return farm.pid || farm.pid === 0
    })

    // // // TP1 // TP2 console.log((`fetchFarmsPublicDataAsync ${JSON.stringify(farmsWithoutHelperLps)}`);

    return farmsWithoutHelperLps
  },
)

// TODO ASYNC TVL
export const fetchFarmsTVLAsync = createAsyncThunk<string>(

  'farms/fetchFarmsTVLAsync',
  async () => {


    let tmpTotalTVL2 = new BigNumber(0);
    let tmpData;

    try {
      await fetch(`${VAULTAPI_URL}/tvl`, { mode: 'cors' })
        .then((response) => { return response.json() })
        .then((data) => {

          // TODO
          // Hardcoded Arbitrum ChainID 
          tmpData = data["42161"] ?? "";
//          // // TP1 // TP2 console.log((`tmpTVL : ${JSON.stringify(tmpData)}`)
        })
    }
    catch (err) {
      //  // // TP1 // TP2 console.log(('there was an error', err);
    }

    Object.values(tmpData).forEach(v => {

      tmpTotalTVL2 = tmpTotalTVL2.plus(new BigNumber(+v));

    }



    );

    tmpTotalTVL2 = tmpTotalTVL2.multipliedBy(1000000000000000000);


    return tmpTotalTVL2.toString();

  },
)


interface FarmUserDataResponse {
  pid: number
  allowance: string
  tokenBalance: string
  stakedBalance: string
  earnings: string
  depositedAt: string
  feeDuration: string
  feePercentage: string
}

export const fetchFarmUserDataAsync = createAsyncThunk<FarmUserDataResponse[], { account: string; pids: number[] }>(
  'farms/fetchFarmUserDataAsync',
  async ({ account, pids }) => {
    
    // console.log(`=== fetchFarmUserDataAsync ${new Date()} ===`);

    const farmsToFetch = farmsConfig.filter((farmConfig) => pids.includes(farmConfig.pid))
    const userFarmAllowances = await fetchFarmUserAllowances(account, farmsToFetch)
    const userFarmTokenBalances = await fetchFarmUserTokenBalances(account, farmsToFetch)
    // TODOBEAR change this to add withfee, duration and pct in one go
    const userStakedBalances = await fetchFarmUserStakedBalances(account, farmsToFetch)
    const userFarmEarnings = await fetchFarmUserEarnings(account, farmsToFetch)

    return userFarmAllowances.map((farmAllowance, index) => {

      
     // console.log(`userStakedbalance.depositedAt : ${userStakedBalances[index].depositedAt} `);
      return {
        pid: farmsToFetch[index].pid,
        allowance: userFarmAllowances[index],
        tokenBalance: userFarmTokenBalances[index],
        stakedBalance: userStakedBalances[index].stakedBalance,
        earnings: userFarmEarnings[index],
        depositedAt: userStakedBalances[index].depositedAt,
        feeDuration: userStakedBalances[index].feeDuration,
        feePercentage: userStakedBalances[index].feePercentage

      }
    })
  },
)

export const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setLoadArchivedFarmsData: (state, action) => {
      const loadArchivedFarmsData = action.payload
      state.loadArchivedFarmsData = loadArchivedFarmsData
    },
  },
  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchFarmsPublicDataAsync.fulfilled, (state, action) => {
      state.data = state.data.map((farm) => {
        const liveFarmData = action.payload.find((farmData) => farmData.pid === farm.pid)
        return { ...farm, ...liveFarmData }
      })
    })

    // Update farms with user data

    // TODO check the state.data spread ... results
    builder.addCase(fetchFarmUserDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { pid } = userDataEl
        const index = state.data.findIndex((farm) => farm.pid === pid)
        state.data[index] = { ...state.data[index], userData: userDataEl }

      })
      state.userDataLoaded = true
    })

    builder.addCase(fetchFarmsTVLAsync.fulfilled, (state, action) => {

      state.totalTVL = action.payload.toString()
    })
  },
})

// Actions
export const { setLoadArchivedFarmsData } = farmsSlice.actions

export default farmsSlice.reducer
